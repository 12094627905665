import { apiFetch, requestHeaders } from './CoreApiService';

const getSettings = async () => {
  const requestOptions = { method: 'GET', headers: requestHeaders };
  return apiFetch("api/global/getSettings", requestOptions, false);
}

const auth = async (credentials, apiUrl) => {
  const url = (apiUrl || '') + '/api/client' + (apiUrl ? '': '/auth');
  const requestOptions = { method: 'POST', headers: requestHeaders, body: JSON.stringify(credentials) };
  return apiFetch(url, requestOptions, false);
}

const validate = async (apiUrl) => {
  const url = (apiUrl || '') + '/api/client/validate';
  const requestOptions = { method: 'GET', headers: requestHeaders };
  return apiFetch(url, requestOptions);
}

const getWebReportsToken = async (Username) => {
  const url = '/api/client/getWebReportsToken';
  const data = { Username }
  const requestOptions = { method: 'POST', headers: requestHeaders, body: JSON.stringify(data) };
  return apiFetch(url, requestOptions);
}

export {
  getSettings, auth, validate, getWebReportsToken
};