import React, { useContext, useState, useEffect } from 'react';
import { GlobalContext } from '../../contexts/GlobalContext';
import { getWebReportsToken } from '../../services/GlobalApiService'
import { useNavigate } from 'react-router-dom';
import './appsLauncher.css';
import { Atom } from "react-loading-indicators";

const AppsLauncher = () => {

  const { user, logOut, casinoBOToken, settings } = useContext(GlobalContext);
  const navigate = useNavigate();
  const [accountMenuVisible, setAccountMenuVisible] = useState(false);
  const [selectedIframeToolId, setSelectedIframeToolId] = useState(0);
  const [showLoading, setShowLoading] = useState(false);
  const [modules, setModules] = useState([
    { name: "Administrator", link: "gbsadm", icon: "assets/images/modules/admin.svg", features: ["Manage Sports", "Manage games and futures", "Manage lines.", "Grade events"], color: "primary", isApp: true, enabled: true },
    { name: "Customer Maintenance", link: "gbscm", icon: "assets/images/modules/cm.svg", features: ["Personal info", "Limits", "Transactions", "Free plays", "More.."], color: "success", isApp: true, enabled: true },
    { name: "Ticket Writer", link: "gbstw", icon: "assets/images/modules/tw.svg", features: ["Straight bets", "Parlays", "Teasers", "If Bets", "Futures", "More..."], color: "danger", isApp: true, enabled: true },
    { name: "Web Reports", link: "webrpts", icon: "assets/images/modules/reports.svg", features: ["Customers performance", "Scores", "Wager details", "Weekly figures", "More..."], isApp: false, enabled: true },
    { name: "Reports", link: "gbsrpt", icon: "assets/images/modules/reports.svg", features: ["Customers performance", "Scores", "Wager details", "Weekly figures", "More..."], isApp: true, enabled: true },
    { name: "Instant Action", link: "gbsia", icon: "assets/images/modules/ia.svg", features: ["Live Wager action", "Risk management"], color: "info", isApp: true, enabled: true },
    { name: "Casino BO", link: "", icon: "assets/images/modules/casinooffice.svg", features: ["Customers performance", "Scores", "Wager details", "Weekly figures", "More..."], isApp: false, isExternalPage: true, enabled: false },
  ]);

  useEffect(() => {
    const sidebarToggle = document.body.querySelector('#sidebarToggle');
    if (sidebarToggle) {
      sidebarToggle.addEventListener('click', event => {
        event.preventDefault();
        document.body.classList.toggle('sb-sidenav-toggled');
        localStorage.setItem('sb|sidebar-toggle', document.body.classList.contains('sb-sidenav-toggled'));
      });
    }
  }, []);

  useEffect(() => {
    if (casinoBOToken) {
      setModules(prevModules =>
        prevModules.map(module =>
          module.name === "Casino BO" ? { ...module, enabled: true } : module
        )
      );
    }
  }, [casinoBOToken])

  //Aux

  const getYear = () => (new Date()).getFullYear();

  const getModuleCardColor = (module) => {
    return module.color ? "border-" + module.color : "none";
  }

  const getModuleLink = (module) => {
    if (!user) return;
    let url = "gbsremote:" + user.Token + "." + module.link;
    return url;
  }

  const showLoadingWithSeconds = (seconds) => {
    if (showLoading) return;
    setShowLoading(true);
    setTimeout(function () {
      setShowLoading(false)
    }, seconds * 1000);
  }

  //Events

  const onLogOutClick = () => {
    logOut();
  }

  const toggleAccountMenu = () => {
    setAccountMenuVisible(!accountMenuVisible);
  }

  const openModule = (ev, module) => {
    ev.preventDefault();
    var casinoBOToken = localStorage.getItem("casinoBOToken");
    if (module.name === "Web Reports") {
      setSelectedIframeToolId(3);
      return;
    }
    showLoadingWithSeconds(3);
    if (casinoBOToken != null && module.isExternalPage) {
      const parsedToken = JSON.parse(casinoBOToken);
      const token = parsedToken.Token;
      window.open(module.link.replace('tkn', token), '_blank');
    }
    if (!module.isApp && module.link) {
      navigate(['/gbs/' + module.link]);
      return;
    }
    var url = getModuleLink(module);
    document.location.href = url;

    /*this.preloading.classList.remove('hidden');
    let _self = this;
    setTimeout(function () {
      _self.preloading.classList.add('hidden');
    }, 5000);
    */

    return false;
  }

  const onFeatureClick = (module, idx) => {
    if (idx !== module.features.length - 1) return;
    const el = document.createElement('textarea');
    el.value = module.link + ":" + user.Token;
    el.setAttribute('readonly', '');
    el.style.position = 'absolute';
    el.style.left = '-9999px';
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);
  }

  const onModulesMenuClick = () => {
    setSelectedIframeToolId(0);
  }

  const onIframeTookClick = (toolId) => {
    setSelectedIframeToolId(toolId);
  }

  //Renders

  if (!user) return null;

  const renderModules = () => {
    return modules && modules.map((module, index) => {
      return module.enabled && (
        <div className="col-xl-4 col-md-6" key={index}>
          <div className={`card border ${getModuleCardColor(module)}`}>
            <div className="card-header bg-transparent">
              <h5 className="my-0 text-primary"><i className="mdi mdi-bullseye-arrow mr-3"></i>{module.name}</h5>
            </div>
            <div className="card-body">
              <div className="row">
                <div className="col-md-3">
                  <a href="#" onClick={(event) => { openModule(event, module) }}><img alt="" height="90" className="rounded-circle" src={module.icon} /></a>
                </div>
                <div className="col-md-9">
                  <ul>
                    {
                      module.features && module.features.map((feature, sIndex) => {
                        return (
                          <li key={sIndex} onClick={(event) => onFeatureClick(module, sIndex)}>{feature}</li>
                        );
                      })
                    }
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    })
  }

  const renderHeader = () => (
    <nav className="sb-topnav navbar navbar-expand navbar-dark bg-dark">
      <a className="navbar-brand ps-3" href="#">GBS Apps Launcher </a>
      <button className="btn btn-link btn-sm order-1 order-lg-0 me-4 me-lg-0" id="sidebarToggle" href="#!"><i className="fas fa-bars"></i></button>
      <div><img style={{ height: "60px" }} src="assets/images/logo.png" alt="" /></div>
      <form className="d-none d-md-inline-block form-inline ms-auto me-0 me-md-3 my-2 my-md-0">
      </form>
      <ul className="navbar-nav ms-auto ms-md-0 me-3 me-lg-4">
        <li className="nav-item dropdown">
          <a className={`nav-link dropdown-toggle ${accountMenuVisible ? 'show' : ''}`} id="navbarDropdown" href="#" role="button" onClick={toggleAccountMenu} aria-expanded="true"><i className="fas fa-user fa-fw"></i></a>
          <ul className={`dropdown-menu dropdown-menu-end ${accountMenuVisible ? 'show' : ''}`} aria-labelledby="navbarDropdown" data-bs-popper="static">
            <li><a className="dropdown-item" href="#!" onClick={onLogOutClick}><i className="fas fa-sign-out"></i> Logout</a></li>
          </ul>
        </li>
      </ul>
    </nav>
  );

  const renderSideNav = () => (
    <div id="layoutSidenav_nav">
      <nav className="sb-sidenav accordion sb-sidenav-light" id="sidenavAccordion">
        <div className="sb-sidenav-menu">
          <div className="nav">
            {user.WebLoginToolsAccess === 1 && (
              <>
                <div className="sb-sidenav-menu-heading">Tools</div>
                <a className="nav-link" href="#" onClick={onModulesMenuClick}>
                  <div className="sb-nav-link-icon"><i className="fas fa-cubes"></i></div>
                  Modules
                </a>
                <a className="nav-link" href="#" onClick={() => onIframeTookClick(1)}>
                  <div className="sb-nav-link-icon"><i className="fas fa-code"></i></div>
                  Website CMS
                </a>
                <a className="nav-link" href="#" onClick={() => onIframeTookClick(2)}>
                  <div className="sb-nav-link-icon"><i className="fas fa-language"></i></div>
                  Website Translator
                </a>
              </>
            )}
            <div className="sb-sidenav-menu-heading">Installers</div>
            <a className="nav-link" href="/installer/GBSRemote.exe">
              <div className="sb-nav-link-icon"><i className="fas fa-laptop"></i></div>
              GBS Installer
            </a>
            <a className="nav-link" href="/installer/CR13SP26MSI64_0-10010309.MSI">
              <div className="sb-nav-link-icon"><i className="fas fa-clipboard"></i></div>
              Crystall Reports
            </a>
            <a className="nav-link" href="/installer/gbs.zip">
              <div className="sb-nav-link-icon"><i className="fas fa-laptop-code"></i></div>
              GBS Registry
            </a>
          </div>
        </div>
        <div className="sb-sidenav-footer">
          <div className="small">Logged in as:</div>
          {user.Username}
        </div>
      </nav>
    </div>
  );

  const renderInstructions = () => (
    <div className="card mb-4">
      <div className="card-body">
        If this is your first time using our service, please follow these steps:
        <ul>
          <li>Download and execute the installer: Click the "GBS Installer" link in the sidebar to download the installer. For best results, run the installer as an administrator.</li>
          <li>Load modules: After installing, load the application modules from the links provided above.</li>
          <li>Resolve module loading issues: If the modules do not load after installation, download the "GBS Registry" file from the sidebar, and execute it as an administrator.</li>
        </ul>
      </div>
    </div>
  );

  const renderFooter = () => {
    <footer className="py-4 bg-light mt-auto">
      <div className="container-fluid px-4">
        <div className="d-flex align-items-center justify-content-between small">
          <div className="text-muted">Copyright &copy; GBSoft {getYear()}</div>
        </div>
      </div>
    </footer>
  }

  const renderIframe = () => {
    let iframeUrl = null;
    switch (selectedIframeToolId) {
      case 1:
        if (settings.cmsUrl && user.CMSUser && user.CMSPassword)
          iframeUrl = settings.cmsUrl + "&username=" + user.CMSUser + "&password=" + user.CMSPassword;
        break;
      case 2:
        iframeUrl = settings.langUrl;
        break;
      case 3:
        iframeUrl = "/api/services/webreports?login=" + user.Username + "&t="+user.Token;
        break;
      default:
        break;
    }
    if (!iframeUrl) return;
    showLoadingWithSeconds(3);
    return (
      <div className="iframe-container">
        <iframe
          src={iframeUrl}
          sandbox="allow-scripts allow-same-origin"
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
          title="Secure Iframe">
        </iframe>
      </div >
    );
  }

  return (
    <>
      {renderHeader()}
      <div id="layoutSidenav">
        {renderSideNav()}
        <div id="layoutSidenav_content">
          {!selectedIframeToolId && (
            <div className="container-fluid px-4">
              <h1 className="mt-4">Modules</h1>
              <div className="row">
                {renderModules()}
              </div>
              {renderInstructions()}
              {showLoading &&
                <div id="loading-indicator"><Atom className={"loading-indicator"} color="#1f00fc" size="medium" text="" textColor="" /></div>
              }
            </div>
          )}
          {selectedIframeToolId > 0 && renderIframe()}
          {renderFooter()}
        </div>
      </div>
    </>
  );
};
export default AppsLauncher;