import React, { useContext, useState, useEffect } from 'react';
import './LayoutContainer.css';
import LoginForm from '../login/loginForm';
import SplashSCreen from '../splashScreen/SplashScreen';
import AppsLauncher from '../appsLauncher/appsLauncher';

import { GlobalContext } from '../../contexts/GlobalContext';

const LayoutContainer = () => {

  const { isPageLoading, user } = useContext(GlobalContext);

  return (
    <>
      {isPageLoading ? <SplashSCreen /> : (user != null ? <AppsLauncher /> : <LoginForm />)}
    </>
  );
};

export default LayoutContainer;