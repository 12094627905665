const requestHeaders = { 'Content-Type': 'application/json' };

const apiCallStart = () => {
  document.dispatchEvent(new Event('apiCallStart'));
};

const apiCallEnd = () => {
  setTimeout(function () {
    document.dispatchEvent(new Event('apiCallEnd'));
  }, 500);
};

const apiFetch = async (api, options, addToken = true, showLoading = false) => {
  if (showLoading) apiCallStart();
  const user = JSON.parse(localStorage.getItem('currentUser'));
  if (addToken && user && user.Token) {
    options.headers = options.headers || {};
    options.headers['Authorization'] = `Bearer ${user.Token}`;
  }
  const result = await fetch(api, options);
  if (showLoading) apiCallEnd();
  return result.json();
};

export {apiFetch, requestHeaders};