// jwtHelper.js
import { jwtDecode } from 'jwt-decode';

/**
 * Checks if a JWT token is valid and not expired.
 * @param {string} token - The JWT token to be checked.
 * @returns {boolean} - Returns true if the token is valid and not expired, otherwise false.
 */
export function isTokenValid(token) {
  if (!token) return false;

  try {
    const decodedToken = jwtDecode(token);
    
    // Check if the token has an 'exp' (expiration) claim
    if (!decodedToken.exp) return false;

    // Get current time in seconds
    const currentTime = Math.floor(Date.now() / 1000);

    // Check if the token is expired
    return decodedToken.exp > currentTime;
  } catch (error) {
    // Token is invalid or could not be decoded
    console.error("Token decoding failed:", error);
    return false;
  }
}