import React, { useState, useEffect } from 'react';

const LoadingIndicator = () => {
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const setLoadingState = (loadingState) => {
      setLoading(loadingState);
    };

    // Listen for custom events
    document.addEventListener('apiCallStart', () => setLoadingState(true));
    document.addEventListener('apiCallEnd', () => setLoadingState(false));

    return () => {
      document.removeEventListener('apiCallStart', () => setLoadingState(true));
      document.removeEventListener('apiCallEnd', () => setLoadingState(false));
    };
  }, []);

  if (!loading) return null;

  return (
    <div style={{ position: 'fixed', top: 0, left: 0, right: 0, bottom: 0, background: 'rgba(0, 0, 0, 0.5)', zIndex: 9999 }}>
      <div style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}>
        <h2>Loading...</h2>
      </div>
    </div>
  );
};

export default LoadingIndicator;