import React, { createContext, useState, useEffect } from 'react';
import { getSettings, auth, validate } from '../services/GlobalApiService';
import { isTokenValid } from '../helpers/JwtHelper';

const GlobalContext = createContext();

const GlobalContextProvider = ({ children }) => {

  const [settings, setSettings] = useState(null);
  const [user, setUser] = useState(null);
  const [isPageLoading, setPageLoading] = useState(true);
  const [casinoToken, setCasinoToken] = useState(null);

  useEffect(() => {
    getSettings().then(function (data) {
      setSettings(data);
    });

  }, []);

  useEffect(() => {
    if (settings) {
      const lUser = JSON.parse(localStorage.getItem("currentUser"));
      if (lUser) {
        if (!lUser.Token || !isTokenValid(lUser.Token)) {
          localStorage.removeItem("currentUser");
          setUser(null);
          setPageLoading(false);
        } else setUser(lUser);
      }
      else {
        setUser(null);
        setPageLoading(false);
      }
      reviewUserToken();
    }
  }, [settings]);

  useEffect(() => {
    setPageLoading(false);
  }, [user]);

  //Aux

  function reviewUserToken() {
    const checkTokenValidity = () => {
      const lUser = JSON.parse(localStorage.getItem("currentUser"));
      if (lUser && lUser.Token) {
        if (!isTokenValid(lUser.Token)) logOut();
        else {
          if (settings) validate(settings.ApiUrl).then().catch((error) => logOut());
        }
      }
    };
    const intervalId = setInterval(checkTokenValidity, 60000);
    checkTokenValidity();
    return () => clearInterval(intervalId);
  }

  const authUser = (username, passwrod, onError) => {
    auth({ ...settings, Username: username, Password: passwrod }, settings.ApiUrl).then(function (result) {
      if (result && result.Message === "OK" && result.User) {
        const user = result.User;
        localStorage.setItem('currentUser', JSON.stringify(user));
        const casinoToken = result.CasinoBOToken;
        if (casinoToken) {
          localStorage.setItem('casinoBOToken', JSON.stringify(casinoToken));
          setCasinoToken(casinoToken);
        }
        setUser(user);
      }
      else {
        if (onError) onError(result.Message);
      }
      setPageLoading(false);
    });
  }

  const logOut = () => {
    localStorage.removeItem("currentUser");
    setUser(null);
  }

  return (
    <GlobalContext.Provider value={{ settings, isPageLoading, user, authUser, casinoToken, logOut }}>
      {children}
    </GlobalContext.Provider>
  );
};
export { GlobalContext, GlobalContextProvider };