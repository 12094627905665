import React, { useContext, useState } from 'react';
import { GlobalContext } from '../../contexts/GlobalContext';
import './loginForm.css';

const LoginForm = () => {

  const { authUser } = useContext(GlobalContext);
  const [userName, setUserName] = useState("");
  const [password, setPassword] = useState("");
  const [errorMsg, setErrorMsg] = useState("");

  const onLoginError = (msg) => {
    console.log(msg);
    setErrorMsg(msg);
    setTimeout(function () {
      setErrorMsg("");
    }, 5000)
  }

  const onLoginClick = () => {
    authUser(userName, password, onLoginError);
  }

  const showErrorMsg = () => {
    return errorMsg && (
      <div className="alert alert-danger" id="success-danger">
        <strong>Error!</strong> {errorMsg}
      </div>
    );
  }

  return (
    <div className="container mt-5 mb-5">
      <div className="row justify-content-center">
        <div className="col-md-8 col-lg-6 col-xl-5">
          <div className="card overflow-hidden">
            <div className="bg-soft-primary">
              <div className="row">
                <div className="col-7">
                  <div className="text-white p-4">
                    <h5 className="text-primary">Welcome Back !</h5>
                  </div>
                </div>
                <div className="col-5 align-self-end"><img src="assets/images/logo.png" alt="" className="img-fluid" /></div>
              </div>
            </div>
            <div className="card-body pt-0">
              <div >
                <a href="/">
                  <div className="avatar-md profile-user-wid mb-4"><span className="avatar-title rounded-circle bg-light"><img src="assets/images/logo-light.svg" alt="" height="34" className="rounded-circle" /></span></div>
                </a>
              </div>
              <div className="p-2">
                <form className="form-horizontal">
                  <div className="form-group mb-3">
                    <label htmlFor="username">Username</label><input type="text" id="username" placeholder="Username" className="form-control" value={userName} onChange={(event) => setUserName(event.target.value)} />
                  </div>
                  <div className="form-group mb-3">
                    <label htmlFor="password">Password</label><input type="password" id="password" placeholder="Password" className="form-control" value={password} onChange={(event) => setPassword(event.target.value)} />
                  </div>
                  <div className="custom-control custom-checkbox"><input type="checkbox" id="customControlInline" className="custom-control-input" /><label htmlFor="customControlInline" className="custom-control-label">Remember me</label></div>
                  <div className="mt-3"><button type="button" onClick={onLoginClick} className="btn btn-primary btn-block btn-with-loading">Log In</button></div>
                  <div className="mt-4 text-center">
                    {showErrorMsg()}
                  </div>
                </form>
              </div>
            </div>
          </div>
          <div className="mt-5 text-center"></div>
        </div>
      </div>
    </div>
  );
};
export default LoginForm;