import React, { useEffect, useState } from 'react';
import { GlobalContextProvider } from './contexts/GlobalContext';
import LayoutContainer from './components/layout/LayoutContainer';
import './custom.css';

const App = () => {

  useEffect(() => {
  }, []);


  return (
    <GlobalContextProvider>
      <LayoutContainer>
      </LayoutContainer>
    </GlobalContextProvider>
  );
};

export default App;